@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: #027cbb;
  text-decoration: underline;
}
a:hover {
  color: #075985;
}

.ot-sdk-show-settings {
  background-color: transparent !important;
  border: none !important;
  color: #0369a1 !important;
  font-size: .875rem !important;
  font-weight: 600 !important;
  letter-spacing: .05em !important;
  line-height: 1.25rem !important;
  padding: 0 !important;
}

.ot-sdk-show-settings:hover {
  background-color: transparent !important;
}


.otnotice-section-content tbody tr {
  color: #5d626e !important;
}